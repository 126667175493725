import type { Direction, DirectionWithId } from "~/types/calculator";

export function getDirectionsList(
  directions: Direction[],
  ids: number[],
): DirectionWithId[] {
  return directions
    .filter((direction) => ids.some((id) => direction.ids.includes(id)))
    .map((direction) => ({
      ...direction,
      id: ids.find((id) => direction.ids.includes(id)) as number,
    }));
}

export function getDirectionItem(
  directions: Direction[],
  id: number,
): DirectionWithId | null {
  const direction = directions.find((dir) => dir.ids.includes(id));
  return direction ? { id, ...direction } : null;
}
